import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import "../styles/index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import { indexContent } from "../content/index"
import { SettingsContext, actions } from "../contexts/settings"

const IndexPage = () => {
    const [settings, dispatchSettingsAction] = useContext(SettingsContext)
    const content = indexContent[settings.language]
    const [isInitialLoad, setIsInitialLoad] = useState(true)

    useEffect(
        () => {
            if (!isInitialLoad) {
                console.log(settings)
                localStorage.setItem("language", settings.language)
                return
            }
            const storedLanguage = localStorage.getItem("language")
            if (storedLanguage !== null) {
                dispatchSettingsAction(actions.changeLanguage(storedLanguage))
            }
            setIsInitialLoad(false)
        },
        [dispatchSettingsAction, isInitialLoad, settings]
    )


    return (
        <div className="page-container-index">
                <div className="content">
                    <header>
                    <div className="language-buttons-index">
                        <button className="button-index" onClick={() => dispatchSettingsAction(actions.changeLanguage("german"))} aria-label={content.buttonGerman}>
                            <span className="black-button-index">DEUTSCH</span>
                            <div className="mint-line-index"></div>
                        </button>
                        <button className="button-index" onClick={() => dispatchSettingsAction(actions.changeLanguage("english"))} aria-label={content.buttonEnglish}>
                            <span className="black-button-index">ENGLISH</span>
                            <div className="mint-line-index"></div>
                        </button>
                    </div>
                    </header>
                    <main>
                        <div className="title-index">
                            <h3 className="date-index">{content.date}</h3>
                            <h1 className="heading1-index">{content.headline}</h1>
                            <div className="mint-line-index"></div>
                            <h2 className="heading2-index">{content.first}</h2>
                            <div className="mint-line-index"></div>
                            <div className="pink-line-index"></div>
                            <div className="transparent-line-index"></div>
                            <div className="mint-line-index"></div>
                        </div>
                        
                        <div className="middle-index">
                            <div >
                                <p>
                                    <Link className="arrow-index" to="/startseite-text" aria-label={content.arrowVereinfacht}>&#60;&#60; <span className="arrow-text">{content.second}</span></Link> 
                                </p>
                            </div>
                            <div className="logo-index">
                                <img className="lower-index" src="/logos/Logo_Startseite/Logo_Startseite-01.svg" alt={content.logo}/>
                                <img className="upper-index" src="/images/Unrest.svg" alt={content.unrest}/>
                            </div>
                            <div>
                                <p>
                                    <Link className="arrow-index" to="/startseite" aria-label={content.arrowWimmelbild}><span className="arrow-text">{content.third}</span>&#62;&#62;</Link> 
                                </p>
                            </div>
                        </div>
                    </main>
                    <footer>
                        <div className="footer-index">
                            <div className="icons-index">
                                <div className="icon-index">
                                    <a href="https://twitter.com/nocturnalunrest" target="_blank" rel="noopener noreferrer" className="fab-index" aria-label="Twitter">
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </a>
                                </div>
                                <div className="icon-index">
                                    <a href="https://www.facebook.com/noufrankfurt/" target="_blank" rel="noopener noreferrer" className="fab-index" aria-label="Facebook">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                </div>
                                <div className="icon-index">
                                    <a href="https://www.instagram.com/nocturnalunrest/" target="_blank" rel="noopener noreferrer" className="fab-index" aria-label="Instagram">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="buttons-down-index">
                            <div className="lower-buttons-index">
                                <div className="lower-button-index">
                                    <a href="https://www.eventbrite.de/o/nocturnal-unrest-33095283589" target="_blank" rel="noopener noreferrer" className="lower-black-button-index">Tickets</a>
                                    <div className="lower-mint-line-index"></div>
                                </div>
                                <div className="lower-button-index">
                                    <a href="https://dlist.server.uni-frankfurt.de/mailman/listinfo/nocturnalunrest" target="_blank" rel="noopener noreferrer" className="lower-black-button-index">nOus-Letter</a>
                                    <div className="lower-mint-line-index"></div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>    
        </div>   
    )
}

export default IndexPage
