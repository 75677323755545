const indexContent = {
    german: {
        date: "20.05. - 24.05.2021",
        headline: "NOCTURNAL UNREST",
        first: "Ein feministisches Festival für Performance, Theorie und radikale Flâneuserie",
        second:" Text",
        third: "Bild ",
        buttonGerman: "zu deutscher Sprache wechseln",
        buttonEnglish: "zu englischer Sprache wechseln",
        arrowVereinfacht: "zur vereinfachten Startseite",
        arrowWimmelbild: "zur Startseite mit Wimmelbild",
        logo: "Nocturnal Unrest Logo, darüber ist in türkiser Handschrift das Wort Unrest geschrieben.",
        unrest:"Schriftzug Unrest",

    },
    english: {
        date: "20.05. - 24.05.2021",
        headline: "NOCTURNAL UNREST",
        first: "A feminist Festival for Theory, Performance and Radical Flâneuserie",
        second: " Text",
        third: "Image ",
        buttonGerman: "switch to german language",
        buttonEnglish: "switch to englisch language",
        arrowVereinfacht: "to the simple home page",
        arrowWimmelbild: "to the home page with wimmelpicture",
        logo: "Nocturnal Unrest logo, with the word Unrest written above it in turquoise handwriting.",
        unrest:"handwritten unrest",
    }
}
export {indexContent}